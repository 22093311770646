<template>
  <div>
    <h3>Line Login</h3>
  </div>
</template>

<script>
let liff = window.liff;
export default {
  data() {
    return {};
  },
  created() {
    console.log("hello");
    console.log("line", liff);
    liff
      .init({
        liffId: "1655557892-VZMnBojD",
      })
      .then(() => {
        if (!liff.isLoggedIn()) {
          liff.login();
        } else {
          const accessToken = liff.getAccessToken();

          console.log("accestoken :::" + accessToken);

          liff.getProfile().then(function (profile) {
            console.log("profile:::", profile.userId);
            //   localStorage.idLine = profile.userId;
            //   this.$store.dispatch("addidLine", profile.userId);
          });

          // console.log("local:::", localStorage.idLine);

          console.log("liff init success");
        }
      })
      .catch((error) => {
        console.error(error);
      });
  },
  methods: {},
};
</script>

<style lang="scss" scoped></style>
